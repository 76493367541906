import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import WhitePaper from "../components/sections/whitepaper"
import Footer from "../components/sections/footer"

const WhitePaperPage = () => (
  <Layout>
    <SEO title="Bitcoin White Paper" />
    <Navigation />
    <WhitePaper />
    <Footer />
  </Layout>
)

export default WhitePaperPage
