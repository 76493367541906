import React from "react"
import styled from "styled-components"
import { Container } from "../global"
import appstore from "../../images/appstore.png"

const Header = () => {
  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              Technology is deflationary. If technolgoy impacts everything and
              is ever increasing, why are pricing going up?
            </h1>
          </HeaderTextGroup>
          <iframe
            src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Fbitcoin.pdf?alt=media&token=7216cd35-b943-44df-a839-d7db4f190230"
            width="100%"
            height="500px"
          >
            Bitcoin Whitepaper
          </iframe>
          <HeaderTextGroup>
            <h1>
              "Once it gets bootstrapped, there are so many applications if you
              could effortlessly pay a few cents to a website as easily as
              dropping coins in a vending machine." - Satoshi Nakamoto
            </h1>
          </HeaderTextGroup>
          <iframe
            src="https://lightning.network/lightning-network-paper.pdf"
            width="100%"
            height="500px"
          ></iframe>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
